@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
label,
span {
  font-family: 'Roboto';
}

input::-ms-reveal {
  display: none;
}

@layer base {

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: textfield !important;
  }

  /* light mode colors */
  :root[data-theme="light"] {
    --color-sidebar: var(--color-white250);
    --color-layer1: var(--color-white200);
    --color-layer2: 0deg 0% 96%;
    --color-layer3: var(--color-white10);
    --color-text_unselected: 240deg 2% 41%;
    --color-text_unselected_secondary: 218deg 3% 55%;
    --color-text_selected: 221deg 25% 12%;
    --color-icon_selected: 221deg 25% 12%;
    --color-icon_unselected: 218deg 3% 55%;
    --color-border_selected: var(--color-white10);
    --color-border_selected2: var(--color-white100);
    --color-border_unselected: 0deg 0% 87%;
    --color-hover: 0deg 0% 80%;
    --color-active: 0deg 0% 70%;
    --color-active_hover: 0deg 0% 80%;
    --color-border_unselected_secondary: 0deg 0% 100%;
    --color-outline_unselected: 0deg 0% 80%;
    --color-outline_hover: 218deg 3% 55%;
    --color-button: 0deg 0% 80%;
    --color-button_enable: 225deg 26% 9%;
    --color-button_hover:
      linear-gradient(0deg, var(--color-button_enable), var(--color-button_enable)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12));
    --color-button_focus:
      linear-gradient(0deg, var(--color-button_enable), var(--color-button_enable)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24));

    --color-blue: 202deg 69% 50%;
    --color-bshadow1: 201deg 27% 90%;
    --color-bshadow2: 202deg 41% 83%;
    --color-green: 144deg 59% 51%;
    --color-gshadow1: 143deg 41% 91%;
    --color-gshadow2: 144deg 45% 85%;

    --color-white0: 0deg 0% 100% 0;
    --color-white10: 0deg 0% 100%;
    --color-white100: 0deg 0% 96%;
    --color-white200: 0deg 0% 93%;
    --color-white250: 0deg 0% 87%;
    --color-white300: 0deg 0% 80%;
    --color-white350: 0deg 0% 70%;

    --color-websraper: 44deg 100% 50%;
    --color-dataentry: #DE82FF;
    --color-generalchat: #50B2EC;
    --color-conversationalbi: #6EE99F;
  }

  /* dark mode colors */
  :root[data-theme="dark"] {
    --color-sidebar: 221deg 27% 9%;
    --color-layer1: 221deg 25% 12%;
    --color-layer2: 221deg 19% 17%;
    --color-layer3: 219deg 19% 11%;
    --color-text_unselected: 224deg 6% 64%;
    --color-text_unselected_secondary: 240deg 2% 41%;
    --color-text_selected: 0deg 0% 96%;
    --color-icon_selected: 0deg 0% 96%;
    --color-icon_unselected: 0deg 0% 47%;
    --color-border_selected: var(--color-blue900);
    --color-border_selected2: 221deg 19% 17%;
    --color-border_unselected: 221deg 27% 9%;
    --color-hover: 221deg 19% 17%;
    --color-active: 218deg, 3%, 55%;
    --color-active_hover: 222deg 9% 28%;
    --color-border_unselected_secondary: 0deg 0% 100%;
    --color-outline_unselected: 219deg 11% 25%;
    --color-outline_hover: 240deg 2% 41%;
    --color-button: 0deg 0% 96%;
    --color-button_enable: var(--color-white200);
    --color-button_hover:
      linear-gradient(0deg, var(--color-button_enable), var(--color-button_enable)),
      linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
    --color-button_focus:
      linear-gradient(0deg, var(--color-button_enable), var(--color-button_enable)),
      linear-gradient(0deg, rgba(255, 255, 255, 0.24), rgba(255, 255, 255, 0.24));

    --color-blue: 202deg 80% 62%;
    --color-blue900: 212deg 28% 21%;
    --color-bshadow1: 207deg 34% 25%;
    --color-bshadow2: 212deg 28% 21%;
    --color-green: 144deg 82% 70%;
    --color-gshadow1: 168deg 22% 25%;
    --color-gshadow2: 167deg 19% 18%;

    --color-white0: 0deg 0% 100% 0;
    --color-white10: 0deg 0% 100%;
    --color-white100: 0deg 0% 96%;
    --color-white200: 0deg 0% 93%;
    --color-white250: 0deg 0% 87%;
    --color-white300: 0deg 0% 80%;
    --color-white350: 0deg 0% 70%;

    --color-websraper: 44deg 100% 50%;
    --color-dataentry: #DE82FF;
    --color-generalchat: #50B2EC;
    --color-conversationalbi: #6EE99F;
  }
}

/* style for autofill textbox */
/* Reference: https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-text_selected) !important;
}

:root {
  color-scheme: dark;
}